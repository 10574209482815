<template>
	<div class="gachi-search2" data-search="results">
		<search :search-data="getSearch" />
		<div class="search-results">
			<results :headers="headers" :items="items"> </results>
		</div>
	</div>
</template>

<script>
import Results from '../../../components/search/Results';
import { ACT_GET_SEARCH_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck } from '@/assets/js/utils';
import Search from '../../../components/search/Search';

export default {
	name: 'SearchResult',
	components: {
		Search,
		Results,
	},
	data: () => ({
		searchTerm: '',
		headers: [
			{
				num: 0,
				sectionClass: 'section-total',
				type: 'total',
				collectionNm: 'total',
				headerTitleText: '전체',
			},
			{
				num: 1,
				sectionClass: 'section-broadcast',
				type: 'thumb',
				collectionNm: 'PT_BROADCAST',
				headerTitleText: '판로TV',
				colum1: 'BRC_TIT',
				img: 'BRC_RPRS_IMG_URL',
				paramKey: 'brcInfoNo',
				paramVal: 'BRC_INFO_NO',
				routerName: ['BroadCast','VodView'],
			},
			{
				num: 2,
				sectionClass: 'section-mss-support',
				type: 'text',
				collectionNm: 'MSS_SUPPORT',
				headerTitleText: '중소벤처기업부 지원사업',
				colum1: 'SPRT_BIZ_NM',
				colum2: 'SPRT_BIZ_PURPOSE',
				paramKey: '',
				paramVal: 'SPRT_BIZ_CD',
				routerName: ['mssSupportBiz',''],
			},
			{
				num: 3,
				sectionClass: 'section-support-biz',
				type: 'text',
				collectionNm: 'SUPT_BIZ_INFO',
				headerTitleText: '지자체 지원사업',
				colum1: 'PBLANC_NM',
				colum2: 'BSNS_SUMRY_CN',
				paramKey: '',
				paramVal: 'PBLANC_URL',
				routerName: ['supportBizInfo',''],
			},
			{
				num: 4,
				sectionClass: 'section-issue',
				type: 'thumb',
				collectionNm: 'ISSUE',
				headerTitleText: '소상공인 포스트',
				colum1: 'ISSUE_TIT',
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'issueId',
				paramVal: 'ISSUE_ID',
				routerName: ['Issue','IssueView'],
			},
			{
				num: 5,
				sectionClass: 'section-playground',
				type: 'thumb',
				collectionNm: 'PLAYGROUND',
				headerTitleText: '소상공인 뉴스',
				colum1: 'PLYGRND_TIT',
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'plygrndId',
				paramVal: 'PLYGRND_ID',
				routerName: ['Playground','PlaygroundView'],

			},
			{
				num: 6,
				sectionClass: 'section-interview',
				type: 'thumb',
				collectionNm: 'INTERVIEW',
				headerTitleText: '소상공인 인터뷰',
				colum1: 'INTERVIEW_TIT',
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'interviewId',
				paramVal: 'INTERVIEW_ID',
				routerName: ['Interview','InterviewView'],

			},
			{
				num: 7,
				sectionClass: 'section-community',
				type: 'thumb',
				collectionNm: 'COMMUNITY',
				headerTitleText: '온라인 진출 지원사례',
				colum1: 'COMUN_BLTARTCL_TIT',
				img: 'THMBNL_IMG_PT_URL',
				paramKey: 'comunBltartclId',
				paramVal: 'COMUN_BLTARTCL_ID',
				routerName: ['Community','CommunityView'],

			},
			{
				num: 8,
				sectionClass: 'section-notice',
				type: 'text',
				collectionNm: 'NOTICE',
				headerTitleText: '공지사항',
				colum1: 'NTC_TIT',
				colum2: 'NTC_CN',
				paramKey: 'ntcId',
				paramVal: 'NTC_ID',
				routerName: ['Notice','NoticeView'],

			},
		],
		items: [],
	}),
	created() {

		this.getSearch();
	},
	methods: {
		getSearch() {
			this.searchTerm = this.$route.query.searchTerm;

			const payload = {
				searchStr: this.searchTerm
			}
			if (this.searchTerm) {
				this.$store
						.dispatch(`common/${ACT_GET_SEARCH_LIST}`, payload)
						.then((res) => {
							if (lengthCheck(res)) {
								this.items = getItems(res);
							}
						})
						.catch((e) => {
							console.error(e);
						});
			}
		},
	},
};
</script>
