<template>
    <div class="container">
        <div class="search-category-wrap">
            <div v-if="items.length > 0" class="search-category">
                <button v-for="(header, idx) in headers" :key="idx"  type="button" class="search-category-button" :class="{ 'is-active': resultIdx === idx }" @click.prevent="showResults(header.type, idx, header.collectionNm)">
                    <template v-for="(item, i) in items">
                        <span class="text">{{ header.headerTitleText }}</span>
                        <strong>{{ idx === 0 ? getResultTotalCount(items) : item.resultSet.result[idx-1].totalSize}}</strong>
                        <span class="unit">건</span>
                    </template>
                </button>
            </div>
        </div>
        <!-- s :전체 검색 결과 -->
        <div v-if="listType === 'total'" class="page-component" :class="{ ' is-search-total': listType === 'total' }">
            <div v-for="(header, idx) in headers" :key="idx">
                <div v-for="(item, i) in items">
                    <section v-if="idx > 0" :key="idx" class="section-result">
                        <header class="section-header">
                            <h3 class="header-title">
                                {{ header.headerTitleText }} <span class="text-strong">{{ item.resultSet.result[idx-1].totalSize }}</span
                            >건
                            </h3>
                            <a v-if="item.resultSet.result[idx-1].totalSize > 3" href="" class="link-more" @click.prevent="showResults(header.type, idx, header.collectionNm)">더보기</a>
                        </header>
                        <!-- [DEV]검색결과가 없는 경우 -->
                        <div v-if="item.resultSet.result[idx-1].realSize === 0" class="no-results-found">
                            <p class="text">검색결과가 없습니다</p>
                        </div>
                        <!-- //[DEV]검색결과가 없는 경우 -->
                        <ul v-if="item.resultSet.result[idx-1].realSize > 0 " class="search-list">
                            <li v-for="(document, d) in item.resultSet.result[idx-1].resultDocuments" :key="d">
                                <!-- start image type -->
                                <template v-if="header.type === 'thumb'">
                                    <a href="javascript:" class="search-item" @click="moveDetail(header.routerName[1], header.paramKey, document[header.paramVal])">
                                        <div class="item-col">
                                    <span class="search-item-thumb">
                                      <img v-if="document[header.img]" :src="document[header.img]" alt="" />
                                    </span>
                                        </div>
                                        <div class="item-col">
                                            <strong class="search-item-title">{{tagRemove(document[header.colum1])}}</strong>
                                        </div>
                                    </a>
                                </template>
                                <!-- end image type -->
                                <!-- start text type -->
                                <template v-if="header.type === 'text'">
                                    <a v-if="header.routerName[0] === 'supportBizInfo'" :href="'https://www.bizinfo.go.kr/'+document[header.paramVal]" class="search-item--text">
                                        <strong class="search-item-title">{{tagRemove(document[header.colum1])}}</strong>
                                        <p class="search-item-desc">{{tagRemove(document[header.colum2])}}</p>
                                    </a>
                                    <a v-else-if="header.routerName[0] === 'mssSupportBiz'" :href="'https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd='+document[header.paramVal]" class="search-item--text">
                                        <strong class="search-item-title">{{tagRemove(document[header.colum1])}}</strong>
                                        <p class="search-item-desc">{{tagRemove(document[header.colum2])}}</p>
                                    </a>
                                    <a v-else href="javascript:" class="search-item--text" @click="moveDetail(header.routerName[1], header.paramKey, document[header.paramVal])">
                                        <strong class="search-item-title">{{tagRemove(document[header.colum1])}}</strong>
                                        <p class="search-item-desc">{{tagRemove(document[header.colum2])}}</p>
                                    </a>
                                </template>
                                <!-- end text type -->
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
        <!-- e :전체 검색 결과 -->
        <!-- s : 검색 결과 -->
        <div v-else class="page-component">
            <section v-for="(single,s_idx) in singleResults" class="section-result">
                <header class="section-header">
                    <h3 class="header-title">
                        {{ headers[resultIdx].headerTitleText }} <span class="text-strong">{{
                            single.resultSet.result[0].totalSize
                        }}</span
                    >건
                    </h3>
                </header>
                <!-- [DEV]검색결과가 없는 경우 -->
                <div v-if="single.resultSet.result[0].realSize === 0" class="no-results-found">
                    <p class="text">검색결과가 없습니다</p>
                </div>
                <!-- //[DEV]검색결과가 없는 경우 -->
                <ul v-if="single.resultSet.result[0].realSize > 0 " class="search-list">
                    <li v-for="(document, d) in single.resultSet.result[0].resultDocuments" :key="d">
                        <!-- 이미지 썸네일 리스트 -->
                        <template v-if="headers[resultIdx].type === 'thumb'">
                            <a href="javascript:" class="search-item" @click="moveDetail(headers[resultIdx].routerName[1], headers[resultIdx].paramKey, document[headers[resultIdx].paramVal])">
                                <div class="item-col">
                                <span class="search-item-thumb">
                                  <img v-if="document[headers[resultIdx].img]" :src="document[headers[resultIdx].img]" alt="" />
                                </span>
                                </div>
                                <div class="item-col">
                                    <strong class="search-item-title">{{tagRemove(document[headers[resultIdx].colum1])}}</strong>
                                </div>
                            </a>
                        </template>
                        <!-- //이미지 썸네일 리스트 -->
                        <!-- 텍스트 리스트 -->
                        <template v-else-if="headers[resultIdx].type === 'text'">

                            <a v-if="headers[resultIdx].routerName[0] === 'supportBizInfo'" :href="'https://www.bizinfo.go.kr/'+document[headers[resultIdx].paramVal]" class="search-item--text">
                                <strong class="search-item-title">{{tagRemove(document[headers[resultIdx].colum1])}}</strong>
                                <p class="search-item-desc">{{tagRemove(document[headers[resultIdx].colum2])}}</p>
                            </a>
                            <a v-else href="javascript:" class="search-item--text" @click="moveDetail(headers[resultIdx].routerName[1], headers[resultIdx].paramKey, document[headers[resultIdx].paramVal])">
                                <strong class="search-item-title">{{tagRemove(document[headers[resultIdx].colum1])}}</strong>
                                <p class="search-item-desc">{{tagRemove(document[headers[resultIdx].colum2])}}</p>
                            </a>
                        </template>
                        <!-- //텍스트 리스트 -->
                    </li>
                </ul>
                <!-- pagination -->
                <supt-biz-pagination page-name="Search" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="single.resultSet.result[0].totalSize" :query="queries" :parent-method-name="pagingMethodName" @pagingChange="pagingChange"></supt-biz-pagination>
                <!-- //pagination -->
            </section>
        </div>
        <!-- e : 검색 결과 -->
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SuptBizPagination from '@/components/support/SuptBizPagination.vue';
import {lengthCheck, ucFirst, getItems, setPaging, tagRemove} from "@/assets/js/utils";
import {ACT_GET_SEARCH_LIST} from "@/store/_act_consts";

export default {
    name: 'Results',
    components: {
        SuptBizPagination,
    },
    computed: {
        ...mapGetters('auth', ['session', 'isAuth']),
        ...mapGetters('common', ['isMobile']),
        queries() {
            return {};
        },
    },
    props: {
        sectionClass: Array,
        headerTitleText: String,
        headerTotalCount: Number,
        isThumbnail: {
            type: Boolean,
            default: false
        },
        isCreator: {
            type: Boolean,
            default: false
        },
        headers: {
            type: Array,
            default: []
        },
        items: {
            type: Array,
            default: []
        }
    },
    mounted() {

    },
    data: () => ({
        totalCount: 100,
        pageSize: 10,
        pageListSize: 5,
        pageNo: 1,
        listType: 'total',
        resultIdx: 0,
        resultTotalCount: 0,
        singleResults:[],
        currentColumn:'total',
        pagingMethodName: 'pagingChange',
    }),
    watch: {
        items(newVal, oldVal) {
            // props가 변경될 때 data()의 값을 초기화합니다.
            this.resetData();
        },
    },
    methods: {
        tagRemove,
        resetData() {
            this.totalCount= 100
            this.pageSize = 10
            this.pageListSize = 5
            this.pageNo = 1
            this.listType = 'total'
            this.resultIdx = 0
            this.resultTotalCount = 0
            this.singleResults =[]
            this.currentColumn ='total'
            this.pagingMethodName = 'pagingChange'
        },
        showResults(type, idx, colNm) {
            this.listType = type;
            this.resultIdx = idx;
            if (this.currentColumn !== colNm) this.pageNo = 1;
            this.currentColumn = colNm;
            if (colNm !== 'total') {
                const payload = {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    listNum: this.pageSize,
                    searchStr: this.$route.query.searchTerm,
                    searchTab: this.currentColumn,
                }
                // 컬렉션 api 호출
                this.$store.dispatch(`common/${ACT_GET_SEARCH_LIST}`, payload)
                    .then((res) => {
                        if (lengthCheck(res)) {
                            this.singleResults = getItems(res);
                            setPaging(this, res);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    })
            }
        },
        getResultTotalCount(items) {
            const results = items[0].resultSet.result;
            let total = 0
            results.forEach(result => {
                total = total + result.totalSize;
            })
            return total;
        },
        moveDetail(routerName, paramKey, paramVal){
            this.$router.push({name: ucFirst(routerName), params: {[paramKey]: paramVal}})
        },
        pagingChange(no) {
            this.pageNo = no;
            this.showResults(this.listType, this.resultIdx,this.currentColumn);
        },
    },
};
</script>
